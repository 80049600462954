@import url("https://fonts.googleapis.com/css2?family=Creepster&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@mixin transition {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

:root {
  --primary: #76ef02;
  --secondary: #6fb000;

  --primary-rgb: 118, 239, 2;
  --secondary-rgb: 111, 176, 0;

  --dark: #030303;
  --dark-rgb: 3, 3, 3;

  --light: #fcfff9;
  --light-rgb: 252, 255, 249;
}

$fz: unquote("calc(1vw + 1vh - .6vmin)");
$time: 1s;

* {
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
  color: var(--light);
  cursor: default;
}

html {
  scroll-behavior: smooth;
  overflow-y: overlay;
  overflow-x: hidden;
}

body {
  position: relative;
  background-color: var(--dark);
}

::selection {
  background-color: rgba(var(--secondary-rgb), 0.5);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

nav {
  background-color: rgba(var(--primary-rgb), 0.02);
  border-bottom: 1px solid rgba(var(--light-rgb), 0.25);
  backdrop-filter: blur(20px);

  .navbar-brand {
    img {
      cursor: pointer;
    }
  }

  .nav-item {
    display: flex;

    a {
      color: var(--light) !important;
      text-transform: uppercase;
      text-align: center;
      font-size: 11px;
      font-style: italic;
      font-weight: 500;
      letter-spacing: 3px;
      cursor: pointer;
      padding: 0px 35px !important;
      @include transition;

      &:last-child {
        padding-right: 0px !important;
      }
    }

    i {
      font-size: 14px;
      color: var(--light) !important;
      @include transition;
      cursor: pointer;
    }

    &:hover {
      a,
      i {
        color: rgba(var(--primary-rgb), 0.5) !important;
        text-shadow: 0px 2px 10px rgba(var(--primary-rgb), 0.5);
      }
    }

    @media (max-width: 992px) {
      justify-content: center;

      &:after {
        display: none;
      }

      a {
        padding: 5px !important;

        &:last-child {
          padding-right: 5px !important;
        }
      }
    }
  }

  @media (max-width: 992px) {
    margin: 0;
    width: 100%;
    padding: 5px 20px !important;
    border-radius: 0 0 10px 10px;

    .navbar-brand {
      img {
        width: 75px;
      }
    }
  }
}

.primary-h {
  position: relative;
  font-family: "Creepster";
  margin: 20px 0px;
  color: var(--primary);
  font-size: 72px;
  line-height: 80px;
  min-width: 50px;
  width: fit-content;
  margin: auto;
  letter-spacing: 5px;
  text-shadow: 0 0px 60px rgba(var(--primary-rgb), 0.3),
    0 0px 40px rgba(var(--secondary-rgb), 0.4),
    0 5px 1px rgba(var(--dark-rgb), 0.6);

  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 48px;
  }
}

.about-img {
  img {
    border-radius: 5px;
    pointer-events: none;
    border: 2px solid transparent;
    @include transition;
  }

  &:hover img {
    border: 2px solid rgba(var(--primary-rgb), 0.25);
    box-shadow: 0px 0px 60px 0px rgba(var(--primary-rgb), 0.25);
    transform: scale(1.025) !important;
  }
}

.primary-p {
  color: var(--light);
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 10px;
    line-height: 28px;
  }
}

.primary-btn {
  position: relative;
  margin: auto 20px;
  color: rgba(var(--light-rgb), 0.9);
  border-radius: 10px;
  padding: 15px 25px;
  font-size: 20px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 2px;
  border: 1px solid rgba(var(--primary-rgb), 0.4);
  background-color: rgba(var(--primary-rgb), 0.8);
  @include transition;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px solid rgba(var(--primary-rgb), 0.75);
    @include transition;
    -webkit-animation: clippath 3s infinite linear;
    animation: clippath 3s infinite linear;
    border-radius: 15px;
  }

  &::after {
    -webkit-animation: clippath 3s infinite -1.5s linear;
    animation: clippath 3s infinite -1.5s linear;
  }

  &:hover {
    transform: scale(1.025);
    color: var(--dark);
    background-color: rgba(var(--primary-rgb), 1);
    filter: drop-shadow(0 0 20px rgba(var(--secondary-rgb), 0.3)) contrast(2)
      brightness(1);
    letter-spacing: 3px;

    &::before,
    &::after {
      border-width: 3px;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 992px) {
    padding: 10px 15px;
    font-size: 16px;
  }

  @-webkit-keyframes clippath {
    0%,
    100% {
      -webkit-clip-path: inset(0 0 98% 0);
      clip-path: inset(0 0 98% 0);
    }
    25% {
      -webkit-clip-path: inset(0 98% 0 0);
      clip-path: inset(0 98% 0 0);
    }
    50% {
      -webkit-clip-path: inset(98% 0 0 0);
      clip-path: inset(98% 0 0 0);
    }
    75% {
      -webkit-clip-path: inset(0 0 0 98%);
      clip-path: inset(0 0 0 98%);
    }
  }

  @keyframes clippath {
    0%,
    100% {
      -webkit-clip-path: inset(0 0 98% 0);
      clip-path: inset(0 0 98% 0);
    }
    25% {
      -webkit-clip-path: inset(0 98% 0 0);
      clip-path: inset(0 98% 0 0);
    }
    50% {
      -webkit-clip-path: inset(98% 0 0 0);
      clip-path: inset(98% 0 0 0);
    }
    75% {
      -webkit-clip-path: inset(0 0 0 98%);
      clip-path: inset(0 0 0 98%);
    }
  }
}

.accent-btn {
  background-color: rgba(var(--secondary-rgb), 0.5);

  &:hover {
    color: var(--dark);
    background-color: rgba(var(--light-rgb), 0.8) !important;

    &::before,
    &::after {
      border-color: rgba(var(--light-rgb), 0.75);
      border-width: 3px;
    }
  }
}

.utility-card {
  min-height: 35vh;
  align-items: center;
  margin: auto;
  background-image: linear-gradient(
    to right,
    #fff 20%,
    rgba(255, 255, 255, 0) 0%
  );
  text-align: left;
  background-position: top;
  background-size: 12px 2px;
  background-repeat: repeat-x;

  h1 {
    font-family: "Creepster";
    color: var(--light);
    font-size: 48px;
    font-style: italic;
    letter-spacing: 5px;
    @include transition;
    text-shadow: 0 20px 60px rgba(var(--primary-rgb), 0.6),
      0 20px 40px rgba(var(--secondary-rgb), 0.4),
      0 20px 10px rgba(var(--dark-rgb), 0.3);
  }

  .primary-btn {
    margin: 0;
  }

  .utility-img {
    height: 20vh;

    img {
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(0px 0px 25px rgba(var(--dark-rgb), 0.4));
    }
  }

  &:nth-child(even) {
    text-align: right;
  }

  @media (max-width: 992px) {
    padding: 30px 0px;
    text-align: center !important;

    .utility-img {
      margin-bottom: 20px;

      img {
        width: 50%;
      }
    }
  }
}

.count-card {
  margin: auto 25px;

  .value,
  .name {
    position: relative;
    font-family: "Creepster";
    color: var(--primary);
    font-size: 48px;
    font-style: italic;
    line-height: 60px;
    margin: auto;
    letter-spacing: 5px;
    text-shadow: 0 0px 120px rgba(var(--primary-rgb), 0.8),
      0 0px 60px rgba(var(--secondary-rgb), 0.75),
      0 3px 10px rgba(var(--dark-rgb), 0.75);
    @include transition;
  }

  .name {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 2px;
    color: var(--light);
  }

  .primary-p {
    font-size: 12px;
    width: 75%;
    line-height: 20px;
    margin: auto;
    text-shadow: 0 0px 30px rgba(var(--light-rgb), 0.8);
    @include transition;
  }

  &:hover {
    .value {
      transform: scale(1.2);
    }

    .name {
      transform: scale(1.1);
    }

    .primary-p {
      transform: scale(1.05);
    }
  }
}

.form-control {
  height: 60px;
  outline: none;
  border-radius: 5px;
  padding: 0 15px;
  margin: auto 10px;
  border: 1px solid rgba(var(--primary-rgb), 0.25) !important;
  background-color: rgba(var(--light-rgb), 0.05) !important;
  color: var(--light) !important;
  font-size: 16px;
  cursor: text;
  @include transition;
}

.form-control:hover,
.form-control:focus {
  box-shadow: 0px 0px 20px -5px rgba(var(--primary-rgb), 0.25) !important;
}

.alert {
  width: fit-content;
  font-weight: 600;
  padding: 5px 10px;
}

.alert.error {
  color: red;
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.2);
}

.alert.sending {
  color: var(--secondary);
  border: 1px solid var(--secondary);
  background-color: rgba(var(--secondary-rgb), 0.2);
}

.alert.success {
  color: green;
  border: 1px solid green;
  background-color: rgba(0, 128, 0, 0.2);
}

.roadmap {
  width: 100%;
  margin: 0 auto;

  .roadmap-item {
    padding: 3em 2em 2em;
    position: relative;
    width: fit-content;
    margin: 20px auto;
    background-color: rgba(var(--dark-rgb), 0.3);
    border: 2px solid rgba(var(--light-rgb), 0.3);
    border-radius: 20px;
    @include transition;

    h1 {
      font-weight: 600;
      font-size: 24px;
      font-style: italic;
      text-transform: uppercase;
      color: var(--primary);
      @include transition;
    }

    p {
      font-size: 18px;
      line-height: 32px;
      color: var(--light);
      opacity: 0.9;
      @include transition;
    }

    &::before {
      content: attr(data-title);
      position: absolute;
      left: 2.3em;
      top: 0.9em;
      display: block;
      font-weight: 700;
      font-style: italic;
      font-size: 14px;
      color: var(--light);
      opacity: 0.5;
      @include transition;
    }

    &::after {
      width: 20px;
      height: 20px;
      display: block;
      top: 0em;
      position: absolute;
      left: -11px;
      border-radius: 10px;
      content: "";
      background: var(--light);
      outline: 2px solid var(--dark);
      border: 4px solid var(--dark);
      transition: background 0.3s ease !important;
    }

    &:first-child {
      border-image: linear-gradient(
          to top,
          rgba(var(--light-rgb), 0.3) 60%,
          rgba(var(--light-rgb), 0)
        )
        1 100%;
    }

    &:last-child {
      border-image: linear-gradient(
          to bottom,
          rgba(var(--light-rgb), 0.3) 60%,
          rgba(var(--light-rgb), 0)
        )
        1 100%;
    }

    &:hover {
      background-color: rgba(var(--primary-rgb), 0.1);

      &:after {
        background: var(--primary);
        transform: scale(1.25);
        outline: 2px solid var(--primary);
        border: 4px solid var(--dark);
      }

      &:before {
        transform: translateX(4px);
        letter-spacing: 1px;
        opacity: 1;
      }

      h1 {
        transform: translateX(8px);
        letter-spacing: 1px;
      }

      p {
        transform: translateX(12px);
      }
    }
  }

  @media (max-width: 992px) {
    width: 95%;
  }
}

.team-card {
  width: 300px;
  margin: auto;
  padding: 30px;
  position: relative;
  overflow: hidden;
  background-color: rgba(var(--light-rgb), 0.05);
  border: 1px solid rgba(var(--light-rgb), 0.25);
  border-radius: 10px;
  @include transition;

  .header {
    position: relative;
    width: 100%;
    @include transition;

    .content {
      @include transition;

      .avatar {
        width: 100%;
        height: 100%;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        object-position: center;
        object-fit: cover;
        margin: auto;
        @include transition;
      }

      .fullname {
        margin: 20px 0px 10px;
        font-family: "Creepster";
        font-size: 32px;
        color: var(--primary);
        letter-spacing: 3px;
        text-shadow: 0px 0px 30px rgba(var(--primary-rgb), 0.5);
        text-align: center;
        @include transition;
      }

      .jobtitle {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        text-align: center;
        opacity: 0.8;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
        color: var(--light);
        text-shadow: 0px 0px 25px rgba(var(--primary-rgb), 0.5);
      }
    }
  }

  .main {
    position: relative;
    flex: 1;

    .section {
      display: block;

      .social {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          height: 32px;
          width: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(var(--light-rgb), 0.1);
          border-radius: 5px;
          margin-right: 15px;
          cursor: pointer;
          @include transition;

          i {
            opacity: 0.8;
            color: var(--light);
            cursor: pointer;
            @include transition;
          }

          &:hover {
            i {
              color: var(--primary);
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &:hover {
    border-color: rgba(var(--primary-rgb), 0.5);
    background-color: rgba(var(--primary-rgb), 0.075);

    .content {
      .avatar {
        transform: scale(1.025);
      }
    }
  }

  @media (max-width: 992px) {
    margin: 10px auto;
  }
}

.faq-card {
  position: relative;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid rgba(var(--primary-rgb), 0.5);
  width: 50%;
  cursor: pointer;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: 0;
    @include transition;
  }

  .title {
    cursor: pointer;
    padding: 15px 20px;
    font-size: 20px;
    position: relative;
    text-align: left;
    color: var(--light);
    width: 100%;
    background-color: transparent;
    text-shadow: 0px 0px 20px rgba(var(--primary-rgb), 0.4);
    @include transition;

    &:after {
      content: "+";
      position: absolute;
      top: 5px;
      right: 20px;
    }
  }

  .text {
    max-height: 0px;
    width: 100%;
    margin: 0 auto;
    opacity: 0;
    overflow: hidden;
    text-align: left;
    border-radius: 10px;
    padding: 0px 20px;
    @include transition;
  }

  .text p {
    color: var(--light);
    opacity: 0.75;
    font-size: 16px;
    line-height: 32px;

    ul,
    ol {
      padding: 0;
      list-style-position: inside;
    }

    li {
      color: var(--light);
      opacity: 0.75;
      font-size: 22px;
      line-height: 42px;
    }
  }

  &:hover {
    .title {
      background: rgba(var(--primary-rgb), 0.1);
    }
  }

  input:checked {
    @include transition;
  }

  input:checked ~ .title {
    background-image: linear-gradient(
      to bottom,
      rgba(var(--primary-rgb), 0.1),
      rgba(0, 0, 0, 0)
    );

    &:after {
      content: "-";
      position: absolute;
      right: 20px;
    }
  }

  input:checked ~ .text {
    opacity: 1;
    max-height: 600px;
  }

  @media (max-width: 992px) {
    width: 90%;

    .title {
      font-size: 20px;
    }

    .text {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

.parallax-img {
  position: relative;
  @include transition;

  img {
    position: absolute;
    right: 0;
    width: 25%;
    transform: rotate(6deg);
    filter: drop-shadow(0 0 100px rgba(var(--secondary-rgb), 0.5)) contrast(1.1);
  }

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 992px) {
    img {
      width: 50%;
    }
  }
}

.mint-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content {
    align-self: center;
    padding: 45px;
    border: 1px solid rgba(var(--primary-rgb), 0.5);
    border-radius: 10px;
  }
}

.qty {
  font-family: "Creepster";
  font-style: italic;
  font-size: 72px;
  letter-spacing: 10px;
  text-shadow: 0 2px 24px var(--primary);

  @media only screen and (max-width: 992px) {
    font-size: 48px;
    letter-spacing: 8px;
  }
}

.buy-qty {
  color: var(--light);
  font-size: 28px;
  padding: 10px;
}

.cost {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 30px;
  width: 300px;
  margin: 0px auto 10px;
}

.qty-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  width: 150px;
  margin: 10px;

  div {
    margin-top: -5px;
    margin-left: -5px;
    color: var(--primary);
    text-align: center;
    font-size: 32px;
    font-style: italic;
    font-family: "Creepster";
    width: 100%;

    @media only screen and (max-width: 992px) {
      font-size: 28px;
      margin-top: -6px;
    }
  }

  button {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 0;
    outline: none;
    border: 1px solid rgba(var(--light-rgb), 0.25);
    background-color: rgba(var(--primary-rgb), 0.1);
    @include transition;
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    @include transition;

    &:hover {
      color: var(--light);
      background-color: rgba(var(--primary-rgb), 0.25);
      filter: drop-shadow(0 0 150px var(--secondary)) contrast(2) brightness(1);
    }
  }

  button.sub {
    left: -0.4rem;
  }

  button.add {
    right: -0.4rem;
  }
}

.aboutVid {
  object-fit: contain;
  pointer-events: none;
  width: 30%;
  height: 30%;
  border-radius: 20px;
  z-index: 8;

  @media only screen and (max-width: 992px) {
    width: 42%;
  }
}

#homeVid {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
}

.vidOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.4);
}

.nft-showcase {
  &:hover .nft-showcase-img {
    opacity: 0.3;
  }

  .nft-showcase-img {
    width: 300px;
    height: 300px;
    overflow: hidden;
    padding: 8px;
    border-radius: 10px;
    @include transition;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
      border: 1px solid rgba(var(--light-rgb), 0.25);
      @include transition;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.01);

      img {
        transform: scale(1.1);
        box-shadow: 0px 0px 15px 0px rgba(var(--primary-rgb), 0.6);
      }
    }
  }
}

.progress-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: var(--dark);

  .loading-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    border-radius: 100px;
    align-items: center;
    border: 14px solid var(--dark);
    animation: logo-border 1s normal forwards;
    animation-delay: 0.36s;
    z-index: 1;
  }

  .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(var(--light-rgb), 0.15);
    border-radius: 100px;
    padding: 0 5px;
    height: 25px;
    width: 30%;
  }

  .progress-value {
    animation: load 1.75s normal forwards;
    border-radius: 100px;
    background: var(--primary);
    height: 20px;
    width: 0;
  }

  @keyframes logo-border {
    to {
      border-color: var(--primary);
    }
  }

  @keyframes load {
    0% {
      width: 5%;
    }

    100% {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    .loading-logo {
      width: 80px;
    }

    .progress {
      width: 60%;
    }
  }
}

footer {
  width: 100vw;
  padding: 25px 0px;
  position: relative;
  border-top: 1px solid rgba(var(--light-rgb), 0.25);
  background-color: rgba(var(--primary-rgb), 0.02);
  backdrop-filter: blur(20px);

  small {
    font-size: 16px !important;
    font-weight: 600;
    color: var(--light);

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: var(--light);
      font-weight: 600;
      cursor: pointer;
      @include transition;

      &:hover {
        color: var(--primary);
      }
    }
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
